import Head from "next/head";

export const GenericPageSEO = ({
  url,
  title,
  description,
  imageOverride = null
}) => {
  return (
    <Head>
      <title>{title}</title>
      <meta name="description" content={description} />
      <link rel="canonical" href={url} />
      <link rel="icon" href="/favicon.ico" />
      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href="/apple-touch-icon.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href="/favicon-32x32.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href="/favicon-16x16.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="192x192"
        href="/android-chrome-192x192.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="512x512"
        href="/android-chrome-512x512.png"
      />
      <meta
        name="google-site-verification"
        content="ie5dOx_TvBQyM4e5nEZiD1tEpywtoZCELEP4Ev5eLFI”"
      />
      <meta
        name="robots"
        content="index, follow, max-image-preview:large, max-snippet:-1, max-video-preview:-1"
      />
      <meta property="og:site_name" content="GoodLaundry" />
      <meta property="og:url" content={url} />
      <meta property="og:locale" content="en_US" />
      <meta property="og:type" content="website" />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta
        property="og:image"
        content={
          imageOverride ||
          "https://cdn.shopify.com/s/files/1/0722/4292/1772/files/Ograph-2.png?v=1677538772"
        }
      />
      <meta property="og:image:height" content="630" />
      <meta property="og:image:width" content="1200" />

      <script type="application/ld+json">
        {JSON.stringify({
          "@context": "https://schema.org",
          "@graph": [
            {
              "@type": "Organization",
              "@id": "https://goodlaundry.com/#/schema/organization/1",
              url: "https://goodlaundry.com",
              name: "GoodLaundry",
              logo: {
                "@id":
                  "https://goodlaundry.com/#/schema/ImageObject/30879418351867"
              },
              image: [
                {
                  "@id":
                    "https://goodlaundry.com/#/schema/ImageObject/30879418351867"
                }
              ],
              sameAs: [
                "https://www.instagram.com/goodlaundryofficial/",
                "https://www.tiktok.com/@goodlaundryofficial",
                "https://www.facebook.com/goodlaundryofficial"
              ]
            },

            {
              "@type": "ImageObject",
              "@id":
                "https://goodlaundry.com/#/schema/ImageObject/30879418351867",
              url: "https://a.storyblok.com/f/190835/x/d17945a198/logo.svg",
              contentUrl:
                "https://a.storyblok.com/f/190835/x/d17945a198/logo.svg",
              width: 387,
              height: 189
            },
            {
              "@type": "WebSite",
              "@id": "https://goodlaundry.com/#/schema/website/1",
              url: "https://goodlaundry.com",
              name: "goodlaundry",
              potentialAction: {
                "@type": "SearchAction",
                target: "https://goodlaundry.com/search?q={search_term_string}",
                "query-input": "required name=search_term_string"
              },

              publisher: {
                "@id": "https://goodlaundry.com/#/schema/organization/1"
              },
              inLanguage: "en"
            },
            {
              "@type": ["WebPage"],
              "@id": url,
              url,
              name: title,
              description,
              about: {
                "@id": "https://goodlaundry.com/#/schema/organization/1"
              },
              breadcrumb: {
                "@id": "https://goodlaundry.com/#/schema/breadcrumb"
              },
              primaryImageOfPage: {
                "@id":
                  "https://goodlaundry.com/#/schema/ImageObject/31168080773371"
              },
              image: [
                {
                  "@id":
                    "https://goodlaundry.com/#/schema/ImageObject/31168080773371"
                }
              ],
              isPartOf: {
                "@id": "https://goodlaundry.com/#/schema/website/1"
              }
            },

            {
              "@type": "ImageObject",
              "@id":
                "https://goodlaundry.com/#/schema/ImageObject/31168080773371",
              url:
                imageOverride ||
                "https://cdn.shopify.com/s/files/1/0722/4292/1772/files/Ograph-2.png?v=1677538772",
              contentUrl:
                imageOverride ||
                "https://cdn.shopify.com/s/files/1/0722/4292/1772/files/Ograph-2.png?v=1677538772",
              width: 1200,
              height: 600
            },
            {
              "@type": "BreadcrumbList",
              "@id": "https://goodlaundry.com/#/schema/breadcrumb",
              itemListElement: [
                {
                  "@type": "ListItem",
                  name: "goodlaundry",
                  position: 1
                }
              ]
            }
          ]
        })}
      </script>
    </Head>
  );
};
