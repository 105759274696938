import { get } from "lodash";
import { useScrollPosition } from "@src/hooks";
import { useEffect, useRef, useState } from "react";

const top = 120;
export const NavWrapper = ({ children }) => {
  const headerRef = useRef(null);

  const [heightHeader, setHeightHeader] = useState(0);
  const positionScroll = useScrollPosition();
  const [scrollTrigger, setScrollTrigger] = useState(0);

  useEffect(() => {
    if (positionScroll >= scrollTrigger) {
      if (positionScroll > top) {
        const header = get(headerRef, "current.offsetHeight", 0);
        setHeightHeader(header);
      }
      setScrollTrigger(positionScroll);
    } else {
      setHeightHeader(0);
      setScrollTrigger(positionScroll);
    }
  }, [positionScroll]);

  return (
    <div
      ref={headerRef}
      style={{ top: `-${heightHeader}px` }}
      className="sticky left-0 top-0 z-50 transition-all"
    >
      {children}
    </div>
  );
};
