import React, { useContext, useEffect } from "react";
import { useStoryblokState, StoryblokComponent } from "@storyblok/react";

import { ShopifyCollectionDAO } from "@services/ShopifyCollectionDAO";
import { ShopifyCollectionService } from "@services/ShopifyCollectionService";
import storyblokInstance from "@services/storyblok-service";
import { ShopContext } from "@context/shopContext";
import { getSeoMetadata, getStoryComponent } from "@utils/helpers";
import { GenericPageSEO } from "@services/Seo/generic-page-seo";
import { NavWrapper } from "@components/Global/Nav/NavWrapper/NavWrapper";

const shopifyCollectionService = new ShopifyCollectionService();

const Home = ({
  story,
  cartAddonsCollection,
  cartSettings,
  globalStoryData
}) => {
  story = useStoryblokState(story, {
    resolveRelations: [
      "featured-products.products",
      "featured-collections.collections_list",
      "collection_Item.collection_item"
    ]
  });

  const { setCartAddonsList, setCartDiscountsSetups } = useContext(ShopContext);
  const {
    content: { global, global_footer }
  } = globalStoryData;
  const {
    content: { body, seo }
  } = story;
  const seoData = getSeoMetadata(seo);

  useEffect(() => {
    setCartAddonsList(cartAddonsCollection);
    const cartSetups = getStoryComponent(cartSettings, "cart_setups");
    setCartDiscountsSetups(cartSetups);
  }, []);
  return (
    <>
      <GenericPageSEO
        url="https://goodlaundry.com"
        title={seoData.title || "Eco-Friendly Laundry Detergent Sheets"}
        description={
          seoData.description ||
          "Experience guilt-free laundry with our store's natural ingredients and plastic-free options. Take a step towards a cleaner Earth with our eco-friendly laundry detergent sheets. Shop now!"
        }
      />
      <NavWrapper>
        {global?.map(blok => (
          <StoryblokComponent blok={blok} key={blok._uid} />
        ))}
      </NavWrapper>
      {body?.map(blok => (
        <StoryblokComponent blok={blok} key={blok._uid} />
      ))}
      {global_footer?.map(blok => (
        <StoryblokComponent blok={blok} key={blok._uid} />
      ))}
    </>
  );
};

export const getStaticProps = async ({ preview = false }) => {
  const { data } = await storyblokInstance.getStory({
    requestString: "cdn/stories/home",
    resolveRelations: [
      "featured-products.products",
      "featured-collections.collections_list",
      "collection_Item.collection_item"
    ]
  });
  const globalStoryData = await storyblokInstance.getStory({
    requestString: "cdn/stories/global",
    resolveRelations: ["featured-collections.collection_items"]
  });
  const {
    data: {
      story: {
        content: {
          cart_settings: [
            {
              collection_items: [
                {
                  content: { collection_id: cartAddonsCollectionId }
                }
              ]
            }
          ],
          cart_settings
        }
      },
      story
    }
  } = globalStoryData;
  const cartAddonsCollection = await shopifyCollectionService.getCollectionById(
    cartAddonsCollectionId
  );
  return {
    props: {
      preview,
      story: data ? data.story : false,
      cartAddonsCollection: new ShopifyCollectionDAO(cartAddonsCollection)
        .productsList,
      cartSettings: cart_settings,
      globalStoryData: story
    }
  };
};

export default Home;
